<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      sectiontitle=""
      :title="title"
      :description="description"
    >
      <ul class="nk-block-tools g-1">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            :title="'Add new ' + title_singular"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent
          >
            <em class="icon ni ni-plus"></em>
            <span class="d-none d-md-inline">{{
              "Add new " + title_singular
            }}</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner">
      <div class="px-1">Feature under construction</div>
    </div>
  </BasePageContent>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const title = ref("Estimates/Sale Order");
    const title_singular = ref("estimate");
    const description = ref("Manage your Estimates/ Sale Order");
    return {
      title,
      title_singular,
      description,
    };
  },
};
</script>